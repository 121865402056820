import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Share2, Info, Twitter, Facebook, X, Sparkles, Gamepad, Gift, ChevronLeft, ChevronRight, Star } from 'lucide-react';
import { Switch } from './components/ui/switch';
import { urls as initialUrls, gameUrls as initialGameUrls } from './urls';
import giftsData from './gifts.json';
import staysData from './stays.json';
import confetti from 'canvas-confetti';

interface RawGiftData {
  title: string;
  unNormalizedProductUrl: string;
  highResolutionImages: string[];
  description: string | null;
  price: {
    value: number;
    currency: string;
  } | null;
}

interface Gift {
  title: string;
  unNormalizedGiftUrl: string;
  highResolutionImages: string[];
  description: string | null;
  price: {
    value: number;
    currency: string;
  } | null;
}

interface Stay {
  thumbnail: string;
  title: string;
  description: string;
  rating: {
    guestSatisfaction: number;
    reviewsCount: number;
  };
  url: string;
}

interface AnimatedButtonProps {
  onClick: () => void;
  className: string;
  children: React.ReactNode;
  animateOnClick?: boolean;
  title?: string;
  type?: "button" | "submit" | "reset";
}

interface PopupProps {
  title: string;
  content: React.ReactNode;
  onClose: () => void;
}

interface HomePageProps {
  onRandomClick: () => void;
  onInfoClick: () => void;
  onAddWebsite: () => void;
  onTerms: () => void;
  onPrivacy: () => void;
}

interface ExternalPageProps {
  url: string;
  onRandom: () => void;
  onShare: () => void;
  onInfo: () => void;
  siteId: string;
  onRandomGame: () => void;
  onRandomGift: () => void;
}

interface GiftPageProps {
  url: string;
  onBack: () => void;
  onRandom: () => void;
  onShare: () => void;
  onInfo: () => void;
  siteId: string;
  onRandomGame: () => void;
  onRandomGift: () => void;
}

const ImageSlider: React.FC<{ images: string[], giftUrl: string }> = ({ images, giftUrl }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const goToPrevious = (e: React.MouseEvent) => {
    e.stopPropagation();
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const handleGiftClick = () => {
    window.open(giftUrl, '_blank');
  };

  return (
    <div className="relative h-96 w-full">
      <div className="absolute top-0 left-0 w-full h-full">
        <img
          src={images[currentIndex]}
          alt={`Gift image ${currentIndex + 1}`}
          className="w-full h-full object-contain"
        />
        {images.length > 1 && (
          <div className="absolute inset-0 flex items-center justify-between p-4">
            <button
              onClick={goToPrevious}
              className="p-2 bg-black bg-opacity-50 text-white rounded-full hover:bg-opacity-75 transition-opacity duration-200"
            >
              <ChevronLeft size={24} />
            </button>
            <div 
              className="w-3/5 h-full cursor-pointer"
              onClick={handleGiftClick}
            />
            <button
              onClick={goToNext}
              className="p-2 bg-black bg-opacity-50 text-white rounded-full hover:bg-opacity-75 transition-opacity duration-200"
            >
              <ChevronRight size={24} />
            </button>
          </div>
        )}
        {images.length === 1 && (
          <div 
            className="w-full h-full cursor-pointer"
            onClick={handleGiftClick}
          />
        )}
      </div>
    </div>
  );
};

const AnimatedButton: React.FC<AnimatedButtonProps> = ({ onClick, className, children, animateOnClick, ...props }) => {
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    if (animateOnClick) {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 300);
    }
    onClick();
  };

  return (
    <button onClick={handleClick} className={`${className} transform transition-all duration-200 hover:scale-105 active:scale-95 ${isAnimating ? 'animate-pulse' : ''}`} {...props}>
      {children}
    </button>
  );
};

const Popup: React.FC<PopupProps> = ({ title, content, onClose }) => {
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={handleOutsideClick}>
      <div className="bg-white p-6 rounded-lg max-w-md w-full relative">
        <AnimatedButton onClick={onClose} className="absolute top-2 right-2 p-1 rounded-full hover:bg-gray-200" animateOnClick={true}>
          <X size={24} />
        </AnimatedButton>
        <h2 className="text-xl font-bold mb-4">{title}</h2>
        <div className="mb-8">{content}</div>
      </div>
    </div>
  );
};

const HomePage: React.FC<HomePageProps> = ({ onRandomClick, onInfoClick, onAddWebsite, onTerms, onPrivacy }) => (
  <div className="flex flex-col min-h-screen relative">
    <div className="absolute top-4 right-4">
      <AnimatedButton onClick={onInfoClick} className="p-2 rounded-full bg-gray-300 text-white hover:bg-gray-700 transition-colors duration-200" title="Info" animateOnClick={true}>
        <Info size={24} className="filter grayscale" />
      </AnimatedButton>
    </div>
    <div className="flex-grow flex flex-col items-center justify-center">
      <h1 className="text-6xl font-bold text-center mb-8">Take me to the weird wonderful web</h1>
      <AnimatedButton onClick={onRandomClick} className="px-10 py-6 bg-red-500 text-white rounded-full text-3xl font-bold hover:bg-red-600 transition-colors duration-200" animateOnClick={true}>
        Please
      </AnimatedButton>
    </div>
    <footer className="bg-black text-brown p-4 flex justify-center space-x-8">
      <button onClick={onAddWebsite} className="text-gray-400 hover:underline">Add a Website</button>
      <button onClick={onTerms} className="text-gray-400 hover:underline">Terms and Conditions</button>
      <button onClick={onPrivacy} className="text-gray-400 hover:underline">Privacy Policy</button>
    </footer>
  </div>
);

const ExternalPage: React.FC<ExternalPageProps> = ({ url, onRandom, onShare, onInfo, siteId, onRandomGame, onRandomGift }) => {
  const [hasClicked, setHasClicked] = useState(() => {
    return sessionStorage.getItem('hasClickedButton') === 'true';
  });

  const handleButtonClick = (action: () => void) => {
    sessionStorage.setItem('hasClickedButton', 'true');
    setHasClicked(true);
    action();
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="flex-grow relative">
        <iframe src={url} className="absolute inset-0 w-full h-full" title="External Content" />
      </div>
      {!hasClicked && (
        <div className="fixed bottom-28 left-1/2 transform -translate-x-1/2 flex flex-col items-center gap-2 text-white hover:scale-105 transition-transform duration-300">
          <span className="text-xl font-semibold tracking-wide drop-shadow-[0_4px_12px_rgba(0,0,0,0.9)] [text-shadow:_0_4px_12px_rgb(0_0_0_/_90%)] text-center">Click the button</span>
          <div className="w-0 h-0 border-l-[12px] border-l-transparent border-t-[16px] border-t-white border-r-[12px] border-r-transparent drop-shadow-[0_4px_12px_rgba(0,0,0,0.9)] animate-bounce-slow" />
        </div>
      )}
      <div className="bg-gray-800 text-gray-400 py-4 px-6 flex items-center justify-between fixed bottom-0 left-0 right-0">
        <AnimatedButton onClick={() => handleButtonClick(onShare)} className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200" animateOnClick={true}>
          <Share2 size={24} />
        </AnimatedButton>
        <div className="flex items-center space-x-4">
          <AnimatedButton onClick={() => handleButtonClick(onRandomGame)} className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Random Game">
            <Gamepad size={24} />
          </AnimatedButton>
          <AnimatedButton onClick={() => handleButtonClick(onRandom)} className="p-4 bg-red-500 text-white rounded-full text-2xl font-bold hover:bg-red-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Randomize">
            <Sparkles size={30} />
          </AnimatedButton>
          <AnimatedButton onClick={() => handleButtonClick(onRandomGift)} className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Random Gift">
            <Gift size={24} />
          </AnimatedButton>
        </div>
        <AnimatedButton onClick={() => handleButtonClick(onInfo)} className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200" animateOnClick={true}>
          <Info size={24} className="filter grayscale" />
        </AnimatedButton>
      </div>
    </div>
  );
};

const GiftPage: React.FC<GiftPageProps> = ({ url, onBack, onRandom, onShare, onInfo, siteId, onRandomGame, onRandomGift }) => {
  const item = JSON.parse(url) as (Gift | Stay);
  const isGift = 'highResolutionImages' in item;

  const handleItemClick = () => {
    if (isGift) {
      window.open((item as Gift).unNormalizedGiftUrl, '_blank');
    } else {
      window.open((item as Stay).url, '_blank');
    }
  };

  const renderGiftCard = (gift: Gift) => (
    <div className="bg-white p-8 rounded-lg shadow-lg max-w-4xl w-full mb-8">
      <div className="flex flex-col lg:flex-row gap-8 items-center">
        <div className="w-full lg:w-1/2 max-w-md">
          <ImageSlider images={gift.highResolutionImages} giftUrl={gift.unNormalizedGiftUrl} />
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-between">
          <div>
            <h1 
              className="text-3xl font-bold mb-4 cursor-pointer hover:underline text-center lg:text-left"
              onClick={handleItemClick}
            >
              {gift.title}
            </h1>
            <p className="text-gray-600 mb-6 text-lg text-center lg:text-left">{gift.description || ''}</p>
          </div>
          <div className="flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0">
            {gift.price ? (
              <p className="text-black text-2xl font-bold">{gift.price.currency}{gift.price.value.toFixed(2)}</p>
            ) : (
              <p className="text-gray-600 text-2xl"></p>
            )}
            <AnimatedButton 
              onClick={handleItemClick} 
              className="px-6 py-3 bg-orange-500 text-white rounded-full text-lg font-bold hover:bg-orange-600 transition-colors duration-200" 
              animateOnClick={true}
            >
              CHECK IT OUT
            </AnimatedButton>
          </div>
        </div>
      </div>
    </div>
  );

  const renderStayCard = (stay: Stay) => (
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full mb-8">
      <div className="flex flex-col lg:flex-row gap-8 items-center">
        <div className="w-full lg:w-1/2">
          <div className="w-full h-64 relative">
            <img src={stay.thumbnail} alt={stay.title} className="w-full h-full object-cover rounded-lg" />
          </div>
        </div>
        <div className="w-full lg:w-1/2 flex flex-col justify-between">
          <div>
            <h2 className="text-3xl font-bold mb-4 cursor-pointer hover:underline">{stay.title}</h2>
            <p className="text-gray-600 mb-6 text-lg">{stay.description}</p>
          </div>
          <div className="flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0">
            <div className="flex items-center">
              <Star size={24} className="text-black mr-2" />
              <span className="text-2xl font-bold">{stay.rating.guestSatisfaction.toFixed(2)}</span>
            </div>
            <AnimatedButton 
              onClick={handleItemClick} 
              className="px-6 py-3 bg-orange-500 text-white rounded-full text-lg font-bold hover:bg-orange-600 transition-colors duration-200" 
              animateOnClick={true}
            >
              CHECK IT OUT
            </AnimatedButton>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 overflow-y-auto pb-36">
        <div className="container mx-auto px-4 py-8 flex flex-col items-center justify-center min-h-full">
          {isGift ? renderGiftCard(item as Gift) : renderStayCard(item as Stay)}
        </div>
      </div>
      <GiftPageBottomBar onBack={onBack} onRandom={onRandom} onShare={onShare} onInfo={onInfo} siteId={siteId} onRandomGame={onRandomGame} onRandomGift={onRandomGift} />
    </div>
  );
};

const GiftPageBottomBar: React.FC<Omit<GiftPageProps, 'url'>> = ({
  onBack,
  onRandom,
  onShare,
  onInfo,
  siteId,
  onRandomGame,
  onRandomGift,
}) => {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-gray-400 py-4 px-6 flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <AnimatedButton onClick={onShare} className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200" animateOnClick={true}>
          <Share2 size={24} />
        </AnimatedButton>
      </div>
      <div className="flex items-center space-x-4">
        <AnimatedButton onClick={onRandomGame} className="p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Random Game">
          <Gamepad size={24} />
        </AnimatedButton>
        <AnimatedButton onClick={onRandom} className="p-4 bg-red-500 text-white rounded-full text-2xl font-bold hover:bg-red-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Randomize">
          <Sparkles size={30} />
        </AnimatedButton>
        <AnimatedButton onClick={onRandomGift} className="p-2 bg-green-500 text-white rounded-full hover:bg-green-600 transition-colors duration-200 flex items-center justify-center" animateOnClick={true} title="Random Gift">
          <Gift size={24} />
        </AnimatedButton>
      </div>
      <div className="flex items-center space-x-4">
        <AnimatedButton onClick={onInfo} className="p-2 rounded-full hover:bg-gray-700 transition-colors duration-200" animateOnClick={true}>
          <Info size={24} className="filter grayscale" />
        </AnimatedButton>
      </div>
    </div>
  );
};

// Main App Component
const App: React.FC = () => {
  const [urls, setUrls] = useState<string[]>(initialUrls);
  const [gameUrls, setGameUrls] = useState<string[]>(initialGameUrls);
  const [gifts, setGifts] = useState<Gift[]>([]);
  const [stays, setStays] = useState<Stay[]>([]);
  const [currentUrl, setCurrentUrl] = useState<string>('');
  const [currentSiteId, setCurrentSiteId] = useState<string>('');
  const [previousUrls, setPreviousUrls] = useState<string[]>([]);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showAddWebsite, setShowAddWebsite] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);
  const [showShare, setShowShare] = useState<boolean>(false);
  const [isExternalPage, setIsExternalPage] = useState<boolean>(false);
  const [newWebsiteUrl, setNewWebsiteUrl] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [subscribeNewsletter, setSubscribeNewsletter] = useState<boolean>(false);
  const [shareUrl, setShareUrl] = useState<string>('');
  const [isGiftPage, setIsGiftPage] = useState<boolean>(false);
  const [visitedUrls, setVisitedUrls] = useState<Set<number>>(new Set());
  const [visitedGames, setVisitedGames] = useState<Set<number>>(new Set());
  const [visitedGifts, setVisitedGifts] = useState<Set<number>>(new Set());

  useEffect(() => {
    setUrls(initialUrls);
    setGameUrls(initialGameUrls);
    
    // Transform the data to match the new interface
    const transformedGifts = (giftsData as RawGiftData[]).map(item => ({
      ...item,
      unNormalizedGiftUrl: item.unNormalizedProductUrl
    })) as Gift[];
    
    setGifts(transformedGifts);
    setStays(staysData as Stay[]);

    const urlParams = new URLSearchParams(window.location.search);
    const siteId = urlParams.get('site');
    const type = urlParams.get('type');

    if (siteId) {
      const urlIndex = parseInt(siteId, 10);
      if (!isNaN(urlIndex)) {
        if (type === 'gift' && urlIndex >= 0 && urlIndex < transformedGifts.length) {
          setCurrentUrl(JSON.stringify(transformedGifts[urlIndex]));
          setCurrentSiteId(siteId);
          setIsExternalPage(true);
          setIsGiftPage(true);
        } else if (type === 'stay' && urlIndex >= 0 && urlIndex < staysData.length) {
          setCurrentUrl(JSON.stringify(staysData[urlIndex]));
          setCurrentSiteId(siteId);
          setIsExternalPage(true);
          setIsGiftPage(true);
        } else if (urlIndex >= 0 && urlIndex < urls.length) {
          setCurrentUrl(urls[urlIndex]);
          setCurrentSiteId(siteId);
          setIsExternalPage(true);
          setIsGiftPage(false);
        }
      }
    }
  }, []);

  const getRandomUrl = (): [string, string] => {
    let availableIndices = Array.from(Array(urls.length).keys())
      .filter(i => !visitedUrls.has(i));

    if (availableIndices.length === 0) {
      setVisitedUrls(new Set());
      availableIndices = Array.from(Array(urls.length).keys());
    }

    const randomIndex = availableIndices[Math.floor(Math.random() * availableIndices.length)];
    setVisitedUrls(prev => new Set([...prev, randomIndex]));
    return [urls[randomIndex], randomIndex.toString()];
  };

  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.8 }
    });
  };

  const handleRandomClick = () => {
    triggerConfetti();
    const [newUrl, newSiteId] = getRandomUrl();
    setPreviousUrls(prev => [...prev, currentUrl]);
    setCurrentUrl(newUrl);
    setCurrentSiteId(newSiteId);
    setIsExternalPage(true);
    setIsGiftPage(false);
    window.history.pushState(null, '', `/?site=${newSiteId}`);
  };

  const handleRandomGame = () => {
    triggerConfetti();
    let availableIndices = Array.from(Array(gameUrls.length).keys())
      .filter(i => !visitedGames.has(i));

    if (availableIndices.length === 0) {
      setVisitedGames(new Set());
      availableIndices = Array.from(Array(gameUrls.length).keys());
    }

    const randomIndex = availableIndices[Math.floor(Math.random() * availableIndices.length)];
    setVisitedGames(prev => new Set([...prev, randomIndex]));
    const newUrl = gameUrls[randomIndex];
    setPreviousUrls(prev => [...prev, currentUrl]);
    setCurrentUrl(newUrl);
    setCurrentSiteId(randomIndex.toString());
    setIsExternalPage(true);
    setIsGiftPage(false);
    window.history.pushState(null, '', `/?site=${randomIndex}&type=game`);
  };

  const handleRandomGift = () => {
    triggerConfetti();
    const allItems = [...gifts, ...stays];
    let availableIndices = Array.from(Array(allItems.length).keys())
      .filter(i => !visitedGifts.has(i));

    if (availableIndices.length === 0) {
      setVisitedGifts(new Set());
      availableIndices = Array.from(Array(allItems.length).keys());
    }

    const randomIndex = availableIndices[Math.floor(Math.random() * availableIndices.length)];
    setVisitedGifts(prev => new Set([...prev, randomIndex]));
    const randomItem = allItems[randomIndex];
    setCurrentUrl(JSON.stringify(randomItem));
    setCurrentSiteId(randomIndex.toString());
    setIsExternalPage(true);
    setIsGiftPage(true);
    window.history.pushState(null, '', `/?site=${randomIndex}&type=${randomItem.hasOwnProperty('highResolutionImages') ? 'gift' : 'stay'}`);
  };

  const handleBackToMain = () => {
    setIsExternalPage(false);
    setIsGiftPage(false);
    sessionStorage.removeItem('hasClickedButton');
    window.history.pushState(null, '', '/');
  };

  const handleInfoClick = () => {
    setShowInfo(true);
    window.history.pushState(null, '', '/info');
  };

  const handleAddWebsite = () => {
    setShowAddWebsite(true);
    window.history.pushState(null, '', '/add');
  };

  const handleTermsClick = () => {
    setShowTerms(true);
    window.history.pushState(null, '', '/terms');
  };

  const handlePrivacyClick = () => {
    setShowPrivacy(true);
    window.history.pushState(null, '', '/privacy');
  };

  const handleShare = () => {
    const shareUrl = `${window.location.origin}/?site=${currentSiteId}`;
    setShareUrl(shareUrl);
    setShowShare(true);
    window.history.pushState(null, '', '/share');
  };

  const handleSubmitNewWebsite = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newWebsiteUrl && !urls.includes(newWebsiteUrl)) {
      setUrls(prev => [...prev, newWebsiteUrl]);
      setNewWebsiteUrl('');
      setEmail('');
      setShowAddWebsite(false);
      setShowConfirmation(true);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Weird Wonderful Web</title>
        <meta name="description" content="Discover the weird and wonderful corners of the web" />
      </Helmet>
      {isExternalPage ? (
        isGiftPage ? (
          <GiftPage url={currentUrl} onBack={handleBackToMain} onRandom={handleRandomClick} onShare={handleShare} onInfo={handleInfoClick} siteId={currentSiteId} onRandomGame={handleRandomGame} onRandomGift={handleRandomGift} />
        ) : (
          <ExternalPage url={currentUrl} onRandom={handleRandomClick} onShare={handleShare} onInfo={handleInfoClick} siteId={currentSiteId} onRandomGame={handleRandomGame} onRandomGift={handleRandomGift} />
        )
      ) : (
        <HomePage onRandomClick={handleRandomClick} onInfoClick={handleInfoClick} onAddWebsite={handleAddWebsite} onTerms={handleTermsClick} onPrivacy={handlePrivacyClick} />
      )}
      {showInfo && (
        <Popup title="How it works" content="Click the button to tumble down the internet rabbit hole! Our random website generator will fling you into the weirdest, most wonderful corners of the web. Expect bizarre sites, quirky games, and gift ideas you didn't know existed. Use the button bar to navigate, share, or just to remember how you got here in the first place." onClose={() => {
          setShowInfo(false);
          window.history.pushState(null, '', '/');
        }} />
      )}
      {showAddWebsite && (
        <Popup title="Add a Website" content={
          <form onSubmit={handleSubmitNewWebsite}>
            <input type="url" value={newWebsiteUrl} onChange={(e) => setNewWebsiteUrl(e.target.value)} placeholder="Enter website URL" className="w-full p-2 border border-gray-300 rounded mb-4" required />
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email address" className="w-full p-2 border border-gray-300 rounded mb-4" required />
            <div className="flex items-center justify-between mb-4">
              <span>Subscribe to newsletter</span>
              <Switch checked={subscribeNewsletter} onCheckedChange={setSubscribeNewsletter} className="data-[state=checked]:bg-gray-100" />
            </div>
            <div className="flex justify-center">
              <AnimatedButton type="submit" className="px-6 py-3 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200" animateOnClick={true} onClick={() => { }}>
                Submit Your Website
              </AnimatedButton>
            </div>
          </form>
        } onClose={() => {
          setShowAddWebsite(false);
          window.history.pushState(null, '', '/');
        }} />
      )}
      {showTerms && (
        <Popup title="Terms and Conditions" content={
          <div>
            <p>Welcome to WeirdWonderfulWeb. By using our service, you agree to the following terms:</p>
            <ul className="list-disc pl-5 mt-2">
              <li>We are not responsible for the content of external websites.</li>
              <li>Use this service at your own risk and discretion.</li>
              <li>Do not use this service for any illegal activities.</li>
              <li>We reserve the right to modify or terminate the service at any time.</li>
            </ul>
          </div>
        } onClose={() => {
          setShowTerms(false);
          window.history.pushState(null, '', '/');
        }} />
      )}
      {showPrivacy && (
        <Popup title="Privacy Policy" content={
          <div>
            <p>At WeirdWonderfulWeb, we respect your privacy:</p>
            <ul className="list-disc pl-5 mt-2">
              <li>We do not collect any personal information.</li>
              <li>Your email address is only used for newsletter subscriptions.</li>
              <li>We do not track your browsing history or the websites you visit.</li>
              <li>We use cookies only for essential functionality of the service.</li>
            </ul>
          </div>
        } onClose={() => {
          setShowPrivacy(false);
          window.history.pushState(null, '', '/');
        }} />
      )}
      {showShare && (
        <Popup title="Share this weird wonderful website" content={
          <div>
            <p className="mb-4">Share this unique link:</p>
            <input type="text" value={shareUrl} readOnly className="w-full p-2 border border-gray-300 rounded mb-4" />
            <div className="flex justify-center space-x-4">
              <AnimatedButton onClick={() => window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}`, '_blank')} className="p-2 bg-blue-400 rounded-full hover:bg-blue-500 transition-colors duration-200" animateOnClick={true}>
                <Twitter size={24} className="text-white" />
              </AnimatedButton>
              <AnimatedButton onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank')} className="p-2 bg-blue-600 rounded-full hover:bg-blue-700 transition-colors duration-200" animateOnClick={true}>
                <Facebook size={24} className="text-white" />
              </AnimatedButton>
            </div>
          </div>
        } onClose={() => {
          setShowShare(false);
          window.history.pushState(null, '', '/');
        }} />
      )}
      {showConfirmation && (
        <Popup title="Confirmation" content={
          <div>
            <p>Thank you for adding a website{subscribeNewsletter ? " and subscribing to our newsletter" : ""}!</p>
            <p>Your submission has been received and will be reviewed shortly.</p>
          </div>
        } onClose={() => setShowConfirmation(false)} />
      )}
    </div>
  );
};

export default App;